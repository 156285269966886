import React from 'react';
import {ProductLineItem} from '@wix/wixstores-client-common-components/dist/es/src/ProductLineItem/ProductLineItem';
import {PaymentStatus, Item} from '../../../../../types/app.types';
import {LayoutDataHook} from '../Layout/Layout';
import {useControllerProps} from '../../ControllerContext';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {getSubscriptionDuration} from './utils';
import {classes} from './ProductLineItemOverride.st.css';
import Download from 'wix-ui-icons-common/on-stage/Download';
import Calendar from 'wix-ui-icons-common/on-stage/Calendar';
import {ADD_TO_CALENDAR_ACTION_ID, MAX_PRODUCT_OPTION_LENGTH, ProductType} from '../../../constants';
import {useStyles} from '@wix/tpa-settings/react';
import s from './ProductLineItem.scss';
import stylesParams from '../../../stylesParams';
import {PaymentOptionType} from '@wix/wixstores-graphql-schema-node';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CommonProductLineItem = ({item, withDivider}: {item: Item; withDivider: boolean}) => {
  const {t} = useTranslation();
  const styleSettings = useStyles();
  const shouldRenderImage = styleSettings.get(stylesParams.THANK_YOU_PAGE_IMAGE_SELECTION);

  const toTitleValueOption = (o: Item['options'][0]) => ({title: o.title, value: o.description});
  const options = item.options
    ? item.options.filter((o) => !o.isFreeText || !!o.description.trim()).map(toTitleValueOption)
    : [];

  const {
    isSubscription,
    subscriptionName,
    subscriptionDuration,
    subscriptionFrequency,
    paymentStatus,
    reportClickOnLinkOnLineItem,
    shouldDisplayMixedCart,
  } = useControllerProps().thankYouPageStore;

  const subscriptionInfo = isSubscription
    ? {
        name: subscriptionName,
        duration: getSubscriptionDuration({
          t,
          subscriptionDuration,
          subscriptionFrequency,
        }),
      }
    : undefined;

  const showDigitalProductDownloadLink =
    item.digitalFileLink && paymentStatus === PaymentStatus.Paid && item.productType === ProductType.Digital;
  const shouldHidePrice = item.renderingConfig?.hidePrice;
  const shouldHideQuantity = item.renderingConfig?.hideQuantity;
  const shouldShowPaymentTypeLabel =
    shouldDisplayMixedCart && item.paymentOption && item.paymentOption !== PaymentOptionType.FULL_PAYMENT_ONLINE;

  const handleClickOnLineItem = () => {
    reportClickOnLinkOnLineItem(item);
  };

  const getPaymentTypeLabel = () => {
    const membershipName = item.selectedMembership?.name?.original;
    switch (item.paymentOption) {
      case PaymentOptionType.DEPOSIT_ONLINE:
        return t('thankyouPage.myCartPaymentMethod.deposit.lineItem', {price: item.formattedDepositAmount});
      case PaymentOptionType.MEMBERSHIP:
        return t('thankyouPage.myCartPaymentMethod.onlinePlan.lineItem', {membership: membershipName});
      case PaymentOptionType.MEMBERSHIP_OFFLINE:
        return membershipName
          ? t('thankyouPage.myCartPaymentMethod.offlinePlan.lineItemWithId', {membership: membershipName})
          : t('thankyouPage.myCartPaymentMethod.offlinePlan.lineItemWithoutId');
      case PaymentOptionType.FULL_PAYMENT_OFFLINE:
        return t('thankyouPage.myCartPaymentMethod.offlinePayment.lineItem');
      /* istanbul ignore next */
      default:
        return '';
    }
  };

  const action = getProductLineAction(item, handleClickOnLineItem);

  return (
    <div data-hook={LayoutDataHook.productLineItem} className={s.productLineItem} key={item.productId}>
      <ProductLineItem withDivider={withDivider}>
        {shouldRenderImage && (
          <ProductLineItem.Image
            product={{name: item.name, media: [item.media?.url ? item.media : null], productType: item.productType}}
          />
        )}
        <ProductLineItem.Name name={item.name} className={classes.myName} />
        {!shouldHidePrice && <ProductLineItem.Price formattedPrice={item.formattedPrice} className={classes.myPrice} />}
        {!shouldHidePrice && (
          <ProductLineItem.TotalPrice formattedTotalPrice={item.formattedTotal} className={classes.myTotalPrice} />
        )}
        {shouldShowPaymentTypeLabel && <ProductLineItem.PaymentTypeLabel label={getPaymentTypeLabel()} />}
        <ProductLineItem.Options
          itemId={Number(item.productId)}
          options={options}
          className={classes.myOptions}
          maxOptionValLength={MAX_PRODUCT_OPTION_LENGTH}
        />
        {!shouldHideQuantity && (
          <ProductLineItem.ReadOnlyQuantity
            message={t('thankYouPage.quantity.label')}
            quantity={item.quantity}
            className={classes.myReadOnlyQuantity}
          />
        )}
        {!!subscriptionInfo && (
          <ProductLineItem.Subscription
            itemId={Number(item.productId)}
            subscription={subscriptionInfo}
            className={classes.mySubscription}
          />
        )}
        {showDigitalProductDownloadLink && (
          <ProductLineItem.Link
            title={t('thankYou.DOWNLOAD_LINK_LABEL')}
            icon={<Download className={classes.linkIcon} />}
            href={item.digitalFileLink}
            onClick={handleClickOnLineItem}
            className={classes.myLink}
          />
        )}
        {action}
      </ProductLineItem>
    </div>
  );
};
function getProductLineAction(item: Item, handleClickOnLineItem: () => void) {
  if (!item.actions?.length) {
    return null;
  }

  const action = item.actions[0];
  return (
    <ProductLineItem.Link
      key={action.actionId}
      title={action.label}
      icon={
        action.actionId === ADD_TO_CALENDAR_ACTION_ID ? (
          <Calendar data-hook={LayoutDataHook.calendarIcon} className={classes.linkIcon} />
        ) : null
      }
      href={action.url}
      onClick={handleClickOnLineItem}
      className={classes.myLink}
    />
  );
}
